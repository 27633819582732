import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 486.000000 485.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,485.000000) scale(0.100000,-0.100000)">
          <path d="M2333 3444 c-148 -54 -219 -234 -149 -377 49 -101 131 -151 246 -152
65 0 82 4 130 30 95 53 160 172 146 269 -9 56 -47 135 -82 168 -76 69 -199 96
-291 62z"/>
          <path d="M2138 2596 c-36 -13 -81 -34 -100 -47 -55 -39 -138 -143 -138 -171 0
-19 139 -102 225 -134 191 -71 405 -72 606 -3 93 32 229 113 229 137 0 48
-113 160 -208 205 l-67 32 -240 3 c-231 2 -242 1 -307 -22z"/>
          <path d="M1284 1970 c-63 -20 -81 -137 -27 -180 13 -10 46 -26 74 -35 38 -13
49 -21 49 -37 0 -20 -4 -21 -75 -15 l-74 7 -7 -36 c-3 -19 -3 -38 0 -42 4 -3
52 -6 107 -7 l101 0 29 33 c23 25 29 42 29 76 0 54 -23 79 -101 110 -31 13
-55 29 -57 39 -3 15 6 17 61 17 65 0 65 0 72 31 4 17 4 33 0 37 -11 11 -147
12 -181 2z"/>
          <path d="M1530 1805 l0 -175 130 0 130 0 0 35 0 35 -69 0 c-74 0 -80 4 -73 52
4 27 6 28 63 28 59 0 59 0 59 30 0 29 -1 29 -62 32 -52 3 -62 6 -64 23 -5 36
15 47 80 43 l61 -3 3 38 3 37 -130 0 -131 0 0 -175z"/>
          <path d="M1915 1966 c-47 -21 -74 -58 -87 -118 -17 -80 3 -150 55 -192 37 -29
44 -31 120 -30 45 0 94 6 110 12 26 11 27 13 27 97 l0 85 -55 0 -55 0 0 -61 0
-61 -27 7 c-44 10 -56 30 -61 93 -7 92 3 102 97 102 l79 0 4 31 c3 22 0 33
-13 40 -26 13 -159 10 -194 -5z"/>
          <path d="M2269 1953 c-20 -51 -99 -300 -99 -312 0 -7 21 -11 54 -11 l54 0 7
40 7 40 53 0 53 0 7 -40 7 -40 54 0 c37 0 54 4 54 13 0 6 -17 64 -36 127 -20
63 -41 135 -48 160 l-12 45 -72 3 c-71 3 -72 2 -83 -25z m96 -114 c8 -29 12
-55 10 -59 -2 -4 -18 -5 -35 -2 -22 3 -29 9 -26 21 25 85 30 101 33 97 2 -2
11 -28 18 -57z"/>
          <path d="M2648 1804 l3 -176 117 3 c131 4 157 14 192 72 15 24 20 50 20 103 0
131 -58 173 -240 174 l-95 0 3 -176z m190 90 c20 -14 22 -24 22 -99 l0 -84
-34 -6 c-68 -13 -71 -9 -74 89 -2 49 -1 95 2 103 7 18 57 16 84 -3z"/>
          <path d="M3041 1818 c-29 -90 -55 -169 -58 -175 -3 -10 11 -13 55 -13 l59 0 7
41 6 40 52 -3 c51 -3 52 -4 61 -40 l9 -38 54 0 c30 0 54 4 54 9 0 5 -22 82
-50 171 -27 89 -50 164 -50 166 0 2 -33 4 -73 4 l-73 0 -53 -162z m141 20 c9
-29 14 -54 13 -56 -6 -7 -56 -9 -60 -2 -6 10 18 110 26 110 4 0 13 -24 21 -52z"/>
          <path d="M3330 1941 l0 -40 48 -3 47 -3 0 -133 0 -132 58 0 57 0 0 134 0 135
48 3 c47 3 47 3 47 38 l0 35 -152 3 -153 3 0 -40z"/>
          <path d="M3702 1818 c-29 -90 -52 -169 -52 -175 0 -9 17 -13 54 -13 l53 0 7
41 6 40 52 -3 c51 -3 52 -4 61 -40 l9 -38 54 0 c34 0 54 4 54 11 0 10 -25 95
-91 307 l-11 32 -72 0 -73 0 -51 -162z m150 -21 c3 -19 -1 -22 -27 -19 -16 2
-31 5 -33 7 -2 2 5 29 14 61 l17 59 13 -43 c7 -23 14 -52 16 -65z"/>
          <path d="M1320 1245 l0 -85 58 0 c32 0 63 5 70 12 13 13 16 51 5 62 -5 4 -8
24 -8 44 0 40 -16 49 -87 51 l-38 1 0 -85z m79 42 c12 -15 5 -27 -16 -27 -7 0
-13 9 -13 20 0 23 13 26 29 7z m11 -78 c0 -12 -7 -19 -20 -19 -14 0 -20 7 -20
24 0 19 4 23 20 19 11 -3 20 -14 20 -24z"/>
          <path d="M1514 1315 c-13 -32 2 -127 21 -141 25 -19 85 -18 113 2 19 13 22 24
22 85 0 67 -1 69 -25 69 -24 0 -25 -2 -25 -65 0 -62 -1 -65 -25 -65 -23 0 -24
4 -27 63 -3 56 -5 62 -26 65 -13 2 -25 -3 -28 -13z"/>
          <path d="M1773 1322 c-25 -2 -34 -9 -39 -28 -9 -34 2 -54 36 -67 40 -15 38
-27 -6 -27 -28 0 -35 -3 -32 -17 4 -22 67 -30 98 -13 34 18 24 71 -17 90 -41
19 -42 30 -3 30 19 0 30 5 30 14 0 16 -23 22 -67 18z"/>
          <path d="M1914 1317 c-3 -8 -4 -45 -2 -83 3 -63 5 -69 26 -72 21 -3 22 -1 22
82 0 80 -1 86 -20 86 -11 0 -23 -6 -26 -13z"/>
          <path d="M2030 1245 c0 -78 2 -85 20 -85 16 0 20 7 20 34 0 49 12 51 40 5 17
-27 32 -39 47 -39 22 0 23 3 23 85 0 78 -2 85 -20 85 -20 0 -25 -17 -21 -62 3
-30 -13 -20 -39 22 -17 28 -32 40 -47 40 -22 0 -23 -3 -23 -85z"/>
          <path d="M2240 1245 l0 -85 61 0 c52 0 60 2 57 18 -2 11 -14 18 -36 20 -42 4
-44 32 -2 32 38 0 41 27 3 32 -39 5 -40 26 -1 30 22 2 34 9 36 21 3 15 -5 17
-57 17 l-61 0 0 -85z"/>
          <path d="M2452 1322 c-54 -8 -47 -80 10 -102 l33 -13 -40 -6 c-55 -8 -55 -35
1 -39 54 -4 90 23 81 60 -5 17 -18 30 -37 36 -39 14 -39 32 0 32 19 0 30 5 30
14 0 14 -39 23 -78 18z"/>
          <path d="M2630 1321 c-46 -13 -39 -85 10 -96 41 -9 38 -25 -5 -25 -28 0 -35
-4 -35 -20 0 -17 7 -20 41 -20 56 0 79 14 79 51 0 24 -7 32 -35 46 -43 20 -45
33 -5 33 36 0 41 25 8 34 -26 6 -24 7 -58 -3z"/>
          <path d="M2893 1320 c-48 -20 -58 -95 -18 -135 32 -33 105 -36 105 -5 0 16 -7
20 -30 20 -35 0 -54 27 -44 65 5 21 12 25 41 25 27 0 34 4 31 18 -4 20 -50 26
-85 12z"/>
          <path d="M3040 1245 l0 -85 55 0 c48 0 55 2 55 20 0 16 -7 20 -30 20 -20 0
-30 5 -30 15 0 9 9 15 25 15 16 0 25 6 25 15 0 9 -9 15 -25 15 -16 0 -25 6
-25 15 0 10 10 15 28 15 34 0 43 7 35 26 -3 10 -22 14 -59 14 l-54 0 0 -85z"/>
          <path d="M3210 1245 c0 -78 2 -85 20 -85 17 0 20 7 20 40 0 22 3 40 8 40 4 0
18 -18 32 -40 17 -28 32 -40 47 -40 22 0 23 3 23 86 0 83 -1 85 -22 82 -16 -2
-23 -10 -25 -28 0 -14 -1 -31 -2 -39 -1 -8 -15 5 -31 27 -18 25 -38 42 -50 42
-18 0 -20 -7 -20 -85z"/>
          <path d="M3410 1310 c0 -15 7 -20 25 -20 24 0 25 -2 25 -65 0 -63 1 -65 25
-65 24 0 25 2 25 65 0 58 2 65 20 65 13 0 20 7 20 20 0 18 -7 20 -70 20 -63 0
-70 -2 -70 -20z"/>
          <path d="M3600 1245 l0 -85 60 0 c53 0 60 2 60 20 0 16 -7 20 -35 20 -24 0
-35 5 -35 15 0 10 10 15 30 15 38 0 41 27 3 32 -42 5 -41 28 1 28 29 0 36 4
36 20 0 18 -7 20 -60 20 l-60 0 0 -85z"/>
          <path d="M3780 1245 c0 -84 0 -85 25 -85 21 0 25 5 25 30 0 19 5 30 14 30 8 0
17 -13 21 -30 5 -23 12 -30 31 -30 27 0 32 26 13 61 -7 15 -7 24 1 35 18 20 4
61 -22 68 -13 3 -42 6 -65 6 l-43 0 0 -85z m88 32 c2 -12 -3 -17 -17 -17 -15
0 -21 6 -21 21 0 25 33 22 38 -4z"/>
          <path d="M0 20 c0 -20 7 -20 2430 -20 2423 0 2430 0 2430 20 0 20 -7 20 -2430
20 -2423 0 -2430 0 -2430 -20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
